<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :required="true"
            :comboItems="searchCndItems"
            type="none"
            itemText="codeName"
            itemValue="code"
            name="searchCnd"
            label="검색구분"
            v-model="searchParam.searchCnd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <c-text
            :required="true"
            label="검색어"
            name="searchNm"
            :placeholder="searchParam.searchCnd == '0' ? '화학물질명' : (searchParam.searchCnd == '1' ? 'CAS NO' : 'KE NO')"
            v-model="searchParam.searchNm">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="materialTable"
      title="화학물질 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="chemId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
export default {
  name: 'material-api-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
        plantCd: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchCndItems: [
        { code: '0', codeName: '국문명' },
        { code: '1', codeName: 'CAS No' },
        { code: '3', codeName: 'KE No' },
      ],
      listUrl: '',
      grid: {
        columns: [
          {
            name: 'chemNameKor',
            field: 'chemNameKor',
            label: '화학물질명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS 번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'keNo',
            field: 'keNo',
            label: 'KE 번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'unNo',
            field: 'unNo',
            label: 'UN 번호',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          // {
          //   name: 'mttrnmkor',
          //   field: 'mttrnmkor',
          //   label: '화학물질명',
          //   align: 'left',
          //   sortable: true,
          // },
          // {
          //   name: 'mttrnmeng',
          //   field: 'mttrnmeng',
          //   label: '화학물질명(영문)',
          //   align: 'left',
          //   sortable: true,
          // },
          // {
          //   name: 'casno',
          //   field: 'casno',
          //   label: 'CAS 번호',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: true,
          // },
          // {
          //   name: 'molecform',
          //   field: 'molecform',
          //   label: '분자식',
          //   align: 'center',
          //   style: 'width:130px',
          //   sortable: true,
          // },
        ],
        data: [],
        height: '100%'
      },
      searchParam: {
        searchNm: '',
        searchCnd: '0',
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
    },
    getList() {
      if (this.searchParam.searchNm.length < 2) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '검색어를 2단어 이상 입력하세요.', // 자재를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = '/api/openapi/materials/kosha';
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          let _resultData = [];
          this.$refs['materialTable'].$refs['compo-table'].clearSelection();
          if (_result.data.response.body.totalCount == 0) {
            this.grid.data = [];
          } else {
            if (_result.data.response.body.items.item.length === undefined) {
              _resultData.push(_result.data.response.body.items.item);
              this.grid.data = _resultData;
            } else {
              this.grid.data = _result.data.response.body.items.item;
            }
          }
        },);
      }
    },
    select() {
      let selectData = this.$refs['materialTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '화학물질을를 선택하세요.', // 자재를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
